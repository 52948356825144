import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { Useprovider } from "..";
import AuthService from "../../services/AuthServices";
import "./topNav.css";
import TopNavServices from "./topNavServices";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TopNav() {
  var jwtToken = localStorage.getItem("token");

  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const userId = jwtDecode ? jwtDecode.Id : null;
  // console.log("jwtDecode", jwtDecode);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const navigate = useNavigate();
  const { path } = useContext(Useprovider);

  function logOut() {
    localStorage.removeItem("token");
    if (localStorage.getItem("token") == null) {
      navigate("/");
    }
  }
  const [userData, setUserData] = useState({});
  const getUser = () => {
    AuthService.getUser(userId)
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleImageChange = (e) => {
    debugger;
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setMessage("Only jpg, jpeg, and png are allowed.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    TopNavServices.uploadProfile(formData)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3 align-items-center">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list"></i>
          </div>
          <div className="dashboard-heading">
            <h1 className="heading-dashboard">Dashboard</h1>
          </div>
          {/* <form className="searchbar margin-left">
                    <div
                        className="position-absolute top-50 translate-middle-y search-icon ms-3">
                        <i className="bi bi-search"></i>
                    </div>
                    <input className="form-control" type="text" placeholder=" search" />
                    <div
                        className="position-absolute top-50 translate-middle-y search-close-icon">
                        <i className="bi bi-x-lg"></i>
                    </div>
                </form> */}
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              {/* <li className="nav-item search-toggle-icon">
                <a className="nav-link" href="#">
                  <div className="">
                    <i className="bi bi-search"></i>
                  </div>
                </a>
              </li> */}
              <li className="nav-item dropdown dropdown-user-setting">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  data-bs-toggle="dropdown"
                  style={{ cursor: "pointer" }}
                >
                  <div className="user-setting d-flex align-items-center">
                    <img
                      src={
                        role === "ROLE_ADMIN"
                          ? userData.imageUrl !== null
                            ? userData.imageUrl
                            : "/assets/images/admin.png"
                          : role === "ROLE_CLIENT"
                          ? userData.imageUrl !== null
                            ? userData.imageUrl
                            : "/assets/images/principal.png"
                          : role === "ROLE_DISTRIBUTER"
                          ? userData.imageUrl !== null
                            ? userData.imageUrl
                            : "/assets/images/distributor.png"
                          : "/assets/images/favicon-32x32.png"
                      }
                      className="user-img"
                      alt={userData?.name}
                    />
                    <span className="user-name-first">{userData?.name}</span>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  {/* <li>
                    <a className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            role == "ROLE_ADMIN"
                              ? "/assets/images/admin.JPG"
                              : "/assets/images/Avtar.png"
                          }
                          alt=""
                          className="rounded-circle"
                          width="54"
                          height="54"
                        />
                        <div className="ms-3">
                          <h6 className="mb-0 dropdown-user-name">
                          {userData?.name}
                          </h6>
                          <small className="mb-0 dropdown-user-designation text-secondary">
                            {role == "ROLE_CLIENT"
                              ? "Principal"
                              : role == "ROLE_DISTRIBUTER"
                              ? "Distributer"
                              : role == "ROLE_ADMIN"
                              ? "ADMIN"
                              : null}
                          </small>
                        </div>
                      </div>
                    </a>
                  </li> */}

                  <li>
                    <a className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="edit-profile-pic">
                          <i
                            class="bi bi-pencil-square"
                            style={{ fontWeight: "bold" }}
                          ></i>
                        </div>
                        <div className="profile-img-container">
                          <input
                            type="file"
                            accept="image/jpg, image/jpeg, image/png"
                            id="fileInput"
                            onChange={handleImageChange}
                          />
                          <label htmlFor="fileInput">
                            <img
                              src={
                                role === "ROLE_ADMIN"
                                  ? userData.imageUrl !== null
                                    ? userData.imageUrl
                                    : "/assets/images/admin.png"
                                  : role === "ROLE_CLIENT"
                                  ? userData.imageUrl !== null
                                    ? userData.imageUrl
                                    : "/assets/images/principal.png"
                                  : role === "ROLE_DISTRIBUTER"
                                  ? userData.imageUrl !== null
                                    ? userData.imageUrl
                                    : "/assets/images/distributor.png"
                                  : "/assets/images/favicon-32x32.png"
                              }
                              className="rounded-circle"
                              alt={userData?.name}
                            />

                            <div className="icon-wrapper">
                              <i
                                className="fa fa-camera fa-2x"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </div>
                          </label>
                        </div>
                        <div className="ms-3">
                          <h6 className="mb-0 dropdown-user-name">
                            {userData?.name}
                          </h6>
                          <small className="mb-0 dropdown-user-designation text-secondary">
                            {role === "ROLE_CLIENT"
                              ? "Principal"
                              : role === "ROLE_DISTRIBUTER"
                              ? "Distributer"
                              : role === "ROLE_ADMIN"
                              ? "ADMIN"
                              : null}
                          </small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link className="dropdown-item" to={`changepassword`}>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <div className="ms-3">
                          <span>Change Password</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>{/* <hr className="dropdown-divider" /> */}</li>
                  <li>
                    <li>
                      <button className="dropdown-item" onClick={logOut}>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <i className="bi bi-arrow-right-square-fill"></i>
                          </div>
                          <div className="ms-3">
                            <span>Logout</span>
                          </div>
                        </div>
                      </button>
                    </li>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={alertClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ zIndex: 1500 }} // Ensure Snackbar appears above the modal
      >
        <Alert onClose={alertClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TopNav;
