import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

function Docread(prop) {
  const [readshow, setreadshow] = useState(false);
  const readHandleClose = () => setreadshow(false);
  const readHandleShow = () => setreadshow(true);
  //const url = prop.doc[0].documentUrl;
  // console.log("prop.data.userPrinciple", prop.data.userPrinciple.name);
  const data = prop.data.dcStatus;
  const handleDownload = async (id, documentUrl) => {
    const fileType = documentUrl.split(".").pop();
    console.error("downloading file:", documentUrl);
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Sample-Order-${id}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={readHandleShow}
      >
        {" "}
        <i className="bi bi-eye"></i>
      </button>
      <Modal
        show={readshow}
        size="lg"
        onHide={readHandleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>#{prop.data.id} D/C Notes Details </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body><div dangerouslySetInnerHTML={{ __html: prop.data }}></div>
                            <br/>
                            <a href={`${url}`} target="_blank"><img src="/assets/images/pdf.png" class="" width="40px" height="auto" alt=""/></a>
                            </Modal.Body> */}
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Control
                  value={
                    prop.data.userPrinciple == null
                      ? "null"
                      : prop.data.userPrinciple.name
                  }
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Note Type</b>
                </Form.Label>

                <Form.Control value={prop.data.dcNotes} disabled></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Order ID</b>
                </Form.Label>

                <Form.Control value={prop.data.orderId} disabled></Form.Control>
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirment</b>
                  </Form.Label>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: prop.data.comment,
                    }}
                    style={{
                      maxWidth: "800px", // Set maximum width
                      maxHeight: "200px", // Set maximum height
                      overflowY: "auto", // Enable vertical scrolling
                      border: "1px solid #ccc", // Add a border for clarity
                      padding: "5px", // Add padding for aesthetics
                      wordWrap: "break-word", // Allow word wrapping
                      marginBottom: "15px",
                    }}
                  ></div>

                  {prop?.data?.dcNotesDocumentations?.length > 0 && (
                    <div>
                      <p>
                        <Form.Label>
                          <b>Uploaded Documents</b>
                        </Form.Label>
                      </p>
                      <a
                        onClick={() =>
                          handleDownload(
                            prop?.data?.id,
                            prop?.data?.dcNotesDocumentations[0]?.documentUrl
                          )
                        }
                      >
                        <img
                          src="/assets/images/pdf.png" //changed
                          className="download"
                          width="40px"
                          height="auto"
                          alt=""
                        />
                      </a>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <Form.Label>
                  <b>Track Status</b>
                </Form.Label>
                <div class="history-tl-container">
                  <ul class=" tl virtical">
                    {data?.map((item) => (
                      <li class="tl-item" ng-repeat="item in retailer_history">
                        <div class="timestamp">
                          {dayjs(item.updatedAt).format("DD MMM YYYY")}
                          <br /> {dayjs(item.updatedAt).format("hh : mm A")}
                        </div>
                        <div class="item-title">{item.order_Status_Enum}</div>
                        <div class="item-detail">{item.comment}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={readHandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Docread;
