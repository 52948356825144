import { useEffect, useState } from "react";
import UserServices from "../../services/UserServices";
import ServerSideTable from "../serverSideTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";
import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import dayjs from "dayjs";
import AdminServices from "../../services/AdminServices";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TechnicalQuery() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [queryId, setQueryId] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributerFilter] = useState("");
  const [recentModified, setRecentModified] = useState(false);
  const statusList = ["PENDING", "REJECTED", "APPROVED", "RESOLVED"];
  useEffect(() => {
    console.log(
      "test order",
      orderStatus,
      queryId,
      principalFilter,
      distributerFilter,
      status,
      startDate,
      endDate
    );
  }, [
    orderStatus,
    queryId,
    principalFilter,
    distributerFilter,
    status,
    startDate,
    endDate,
  ]);

  //Add product Form Data
  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");
  const [incoTerms, setIncoTerms] = useState("EXW");
  const [freight, setFreight] = useState("Yes");
  const [insurance, setInsurance] = useState("Yes");

  const [invoice, setInvoice] = useState("Yes");
  const [orderId, setOrderId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [paymentDate, setPaymentDate] = useState();
  const [currency, setCurrency] = useState("$");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  // console.log("jwtDecode", jwtDecode.role[0].name)
  const role = jwtToken ? jwtDecode.role[0].name : null;
  const id = jwtToken ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get principalList API------------
  // function getprincipalList() {
  //   console.log("categoriesGet");
  //   UserServices.searchPrinciple().then((respons) => {
  //     console.log("categoriesGet", respons.data.data);
  //     setPrincipalList(respons.data.data);
  //     //setPrincipalselect(respons.data.data[0].id);
  //   });
  // }

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      setAssignedPrincipalList(respons.data.data);
      // setPrincipalselect(respons?.data?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      // setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
      // setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get Users API------------
  function getGellaryData(e) {
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);
    if (role == "ROLE_DISTRIBUTER") {
      UserServices.technicalEnquiryDistributor(
        page,
        pageSize,
        queryId,
        principalFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log(response.data);
        const { technicalQueries, totalPages, totalElements, data } =
          response.data.data;
        setGellaryData(technicalQueries);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_ADMIN") {
      UserServices.technicalEnquiryAdmin(
        page,
        pageSize,
        queryId,
        principalFilter,
        distributerFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log(response.data);
        const { technicalQueries, totalPages, totalElements, data } =
          response.data;
        setGellaryData(technicalQueries);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else {
      UserServices.technicalEnquiryPrinciple(
        page,
        pageSize,
        queryId,
        distributerFilter,
        recentModified,
        orderStatus,
        status,
        startDate,
        endDate
      ).then((response) => {
        console.log(response.data);
        const { technicalQueries, totalPages, totalElements, data } =
          response.data.data;
        setGellaryData(technicalQueries);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    }
  }
  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    // getprincipalList();
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();
    var today = new Date();

    // Extract the components of the date
    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    var day = today.getDate().toString().padStart(2, "0");

    // Format the date as a string
    var formattedDate = year + "-" + month + "-" + day;
    setPaymentDate(formattedDate);
  }, []);
  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    orderStatus,
    queryId,
    status,
    startDate,
    endDate,
    principalFilter,
    distributerFilter,
    recentModified,
  ]);

  const columns = [
    {
      field: "id",
      headerName: "Enquiry Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrinciple",
      headerName: "Principal",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    // {
    //   field: "currentTechQueryStatus",
    //   headerName: "Current Tech Query Status",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    // },
    {
      headerName: "Status",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param?.row?.techQueryStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {" "}
            <Docread data={param.row} doc={param.row.techQueryDocuments} />
            {param.row.currentTechQueryStatus === "PENDING" ||
            param.row.currentTechQueryStatus === "REJECTED" ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary  mx-1"
                  onClick={() => {
                    setPrincipal(param.row.userPrinciple.id);
                    setProductCode(param.row.productCode);
                    setDescription(param.row.enquiryDetails);
                    setUpdateId(param.row.id);
                    updateHandleShow();
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deletePayment(param.row.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            ) : null}
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];
  const columnsPrincipal = [
    {
      field: "id",
      headerName: "Enquiry Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributor",
      headerName: "Distributor",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productCode",
      headerName: "Product Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param?.row?.techQueryStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* <button type="button" className="btn btn-primary mx-1">
              <i className="bi bi-pencil"></i>
            </button> */}
            {/* <button type="button" className="btn btn-primary ">
              <i className="bi bi-trash"></i>
            </button> */}
          </>
        );
      },
    },
  ];

  const columnsAdmin = [
    {
      field: "id",
      headerName: "Enquiry Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productCode",
      headerName: "Product Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributor",
      headerName: "Distributor",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrinciple",
      headerName: "Principal",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrinciple == null
          ? "null"
          : param.row.userPrinciple.name;
      },
    },

    // {
    //   field: "enquiryDetails",
    //   headerName: "Enquiry Details",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    // },

    // {
    //   field: "currentTechQueryStatus",
    //   headerName: "Current Tech Query Status",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    // },
    {
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param?.row?.techQueryStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Is Approved?",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          const formData = new FormData();
          formData.append("status", e);
          swal({
            title: "Are you sure?",
            text: `You want to Approve #${param.row.id} Technical Enquiry`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.technicalEnquiryApproved(
                param.row.id,
                formData
              ).then((response) => {
                getGellaryData();
                swal("Successfull", {
                  icon: "success",
                });
              });
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
            disabled={param.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* <button type="button" className="btn btn-primary mx-1">
              <i className="bi bi-pencil"></i>
            </button>
            <button type="button" className="btn btn-primary ">
              <i className="bi bi-trash"></i>
            </button> */}
          </>
        );
      },
    },
  ];

  const deletePayment = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Technical Enquiry`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserServices.technicalEnquiryDelete(orderId)
          .then((response) => {
            console.log("Deleted Payment", orderId);
            getGellaryData();
            swal("Successfull", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };
  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => {
    debugger;
    setShow(false);
    setProductName("");
    setDescription("");
  };

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);

  const updateHandleClose = () => {
    setUpdateshow(false);
    setProductName("");
    setDescription("");
  };
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };
  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  function postAddPayment() {
    setLoad(true);
    var formData = new FormData();
    // formData.append("paymentTerms", paymentTerms);
    formData.append("productCode", productCode);
    formData.append("productName", productName);
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    } else {
      formData.append("files", null);
    }

    // formData.append("paymentDate", dayjs(paymentDate).format("YYYY/MM/DD"));
    formData.append("enquiryDetails", description);
    // formData.append("currency", currency);
    formData.append("principalId", principalselect);
    formData.append("user", jwtDecode.Id);
    console.log("formData", files, formData);

    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));

    UserServices.newTechnicalQuery(formData)
      .then((respons) => {
        console.log("galleryPost", respons);
        setShow(false);
        setLoad(false);
        getGellaryData();
        setPrincipalselect("");
        setProductName("");
        setDescription("");
        setProductCode("");
        setProductCodeList([]);
        swal({
          title: "Done",
          text: "New Technical Query has been Created",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log("err", e);
        swal({
          title: "Error!",
          text: "Something went wrong.",
          icon: "error", // 'error' will show a danger alert
          button: "OK",
        });
        setLoad(false);
      });
  }

  function postUpdatePayment() {
    setLoad(true);
    var formData = new FormData();
    formData.append("productCode", productCode);
    formData.append("productName", productName);
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    } else {
      formData.append("files", null);
    }

    formData.append("enquiryDetails", description);
    formData.append("id", updateId);

    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));

    UserServices.technicalEnquiryUpdate(formData, principal)
      .then((respons) => {
        setUpdateshow(false);
        setUpdateId(null);
        setLoad(false);
        getGellaryData();
        swal({
          title: "Done",
          text: "Technical Enquiry has been Updated",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log(e);
        swal({
          title: "Error!",
          text: "Something went wrong.",
          icon: "error", // 'error' will show a danger alert
          button: "OK",
        });
        setLoad(false);
      });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (productCode !== "" && productName !== "") {
      postAddPayment();
      getGellaryData();
    } else {
      setOpen(true);
      setMessage("Please fill required field");
    }

    setValidated(true);
  };

  const [productCodeList, setProductCodeList] = useState([]);
  const [productData, setProductData] = useState([]);

  const getSeletedPrincipalProductList = (principalselect) => {
    UserServices.getSeletedPrincipalProductList(principalselect)
      .then((response) => {
        setProductCodeList(response.data);
        setProductName("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSeletedPrincipalProductListEdit = (principal) => {
    UserServices.getSeletedPrincipalProductList(principal)
      .then((response) => {
        setProductCodeList(response.data);
        // setProductName("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductByProductCode = (productCode) => {
    UserServices.getProductByProductCode(productCode)
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setProductName(productData?.name);
  }, [productData]);
  useEffect(() => {
    // Check if a principal is selected before making the API call
    // if (principalselect !== "") {
    getSeletedPrincipalProductList(principalselect);
    // }
  }, [principalselect]);

  useEffect(() => {
    // Check if a principal is selected before making the API call
    // if (principalselect !== "") {
    getSeletedPrincipalProductListEdit(principal);
    // }
  }, [principal]);

  useEffect(() => {
    // Check if a principal is selected before making the API call
    // if (productCode !== "") {
    getProductByProductCode(productCode);
    // }
  }, [productCode]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Technical Enquiry</h3>
                </div>
                <div class="col-lg-6 text-end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={recentModified}
                        onClick={(e) => setRecentModified(e.target.checked)}
                      />
                    }
                    label="Recently Updated"
                  />
                  {role == "ROLE_DISTRIBUTER" ? (
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleShow}
                    >
                      <i class="fa fa-plus"></i> Create New Technical Enquiry
                    </button>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row">
                    {role == "ROLE_DISTRIBUTER" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setPrincipalFilter(value.id);
                              } else {
                                setPrincipalFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Principal"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Principal", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_CLIENT" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setDistributerFilter(value.id);
                              } else {
                                setDistributerFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Distributor"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="principalfilter"
                              sx={{ width: "100%" }}
                              options={principals}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setPrincipalFilter(value.id);
                                } else {
                                  setPrincipalFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Principal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Principal", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="distirbutorfilters"
                              sx={{ width: "100%" }}
                              options={distributors}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setDistributerFilter(value.id);
                                } else {
                                  setDistributerFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Distributor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="col">
                      <div className="from-group label-figma">
                        <TextField
                          id="outlined-basic"
                          label="Search by Id"
                          variant="outlined"
                          value={queryId}
                          onChange={(e) => setQueryId(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group label-figma">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            Tech Enquiry Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={orderStatus}
                            onChange={(e) => setOrderStatus(e.target.value)}
                            input={
                              <OutlinedInput label="Technical Enquiry Status" />
                            }
                          >
                            <MenuItem key={""} value={""}>
                              All
                            </MenuItem>
                            {statusList.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* <div className="col-lg-3">
                      <div className="from-group label-figma">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            {" "}
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            input={<OutlinedInput label="Status" />}
                          >
                            <MenuItem key={"Approved"} value={"true"}>
                              Approved
                            </MenuItem>
                            <MenuItem key={"Unapproved"} value={"false"}>
                              Unapproved
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div> */}

                    <div className="col">
                      <div className="form-group label-figma">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Start Date"
                            disableFuture
                            sx={{ width: "100%" }}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                // Parse the string date into a Date object
                                const parsedDate = new Date(newValue);

                                // Format the date as yyyy-mm-dd
                                const year = parsedDate.getFullYear();
                                const month = String(
                                  parsedDate.getMonth() + 1
                                ).padStart(2, "0");
                                const day = String(
                                  parsedDate.getDate()
                                ).padStart(2, "0");
                                const formattedDate = `${year}-${month}-${day}`;

                                // Use the formatted date as needed
                                console.log(formattedDate);

                                // Set the formatted date in your state or perform any other actions
                                setStartDate(formattedDate);
                              } else {
                                // Handle the case where the date is null
                                setStartDate(null);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group label-figma">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="End Date"
                            disableFuture
                            sx={{ width: "100%" }}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                // Parse the string date into a Date object
                                const parsedDate = new Date(newValue);

                                // Format the date as yyyy-mm-dd
                                const year = parsedDate.getFullYear();
                                const month = String(
                                  parsedDate.getMonth() + 1
                                ).padStart(2, "0");
                                const day = String(
                                  parsedDate.getDate()
                                ).padStart(2, "0");
                                const formattedDate = `${year}-${month}-${day}`;

                                // Use the formatted date as needed
                                console.log(formattedDate);

                                // Set the formatted date in your state or perform any other actions
                                setEndDate(formattedDate);
                              } else {
                                // Handle the case where the date is null
                                setEndDate(null);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                {count > 0 ? (
                  <ServerSideTable
                    pageSize={10}
                    page={0}
                    count={count}
                    functionName={getGellaryData}
                    data={gellaryData}
                    columns={
                      role == "ROLE_ADMIN"
                        ? columnsAdmin
                        : role == "ROLE_DISTRIBUTER"
                        ? columns
                        : columnsPrincipal
                    }
                    loading={loading}
                    idname="id"
                  />
                ) : (
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <p>
                        <b>No Data Available</b>
                      </p>
                    )}
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----------------------Add Modal----------------- */}
      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>New Technical Query</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  value={principalselect}
                  required
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="" selected>
                    Select Principal
                  </option>{" "}
                  {assignedPrincipalList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setProductCode(e.target.value)}
                  required
                >
                  <option value="" selected>
                    Select Product Code
                  </option>
                  {productCodeList?.map((item) => (
                    <option value={item.productCode}>{item.productCode}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Technical Enquiry</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="">Select Principal</option>
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Select onChange={(e) => setProductCode(e.target.value)}>
                  <option value="" selected>
                    Select Product Code
                  </option>
                  {productCodeList?.map((item) => (
                    <option
                      value={item.productCode}
                      selected={item.productCode == productCode ? true : false}
                    >
                      {item.productCode}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postUpdatePayment}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default TechnicalQuery;
