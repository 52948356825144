import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UserServices from "../services/UserServices";
import { Col, Form, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import http from "../httpcommon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

function UploadExcel(prop) {
  console.log("prop_pagetype", prop.pageType);

  const [show, setShow] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [files, setFiles] = useState();
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState();
  const handleClose = () => setShow(false);
  const ExcelType = prop.pageType;
  const visibility = prop.visibility;
  console.log("visibility", visibility);
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      console.log("fileList_upload_button ", fileList.children[1]);

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const [validated, setValidated] = useState(false);
  const [principalselect, setPrincipalselect] = useState();
  useEffect(() => {
    setPrincipalselect(prop.principalselect);
  }, [prop.principalselect]);
  const principalFilter = prop.principalFilter;
  const assignedPrincipalList = prop.principals;
  const [baseUrls, setBaseUrls] = useState();
  useEffect(() => {
    const baseURL = http.defaults.baseURL;
    setBaseUrls(http.defaults.baseURL);
    console.log("Base URL:", baseURL);
    // You can use baseURL in your component logic
  }, []);
  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  // const downloadExcel = async (id, documentUrl) => {
  //     // const fileType = documentUrl.split(".")[4];
  //      UserServices.budgetExcelDownload(principalFilter).then((response)=>{

  //        const headers = response.headers;

  //      })
  //      try {
  //        const response = await fetch(documentUrl);
  //        const blob = await response.blob();

  //        // Creating a temporary link element
  //        const link = document.createElement("a");
  //        link.href = window.URL.createObjectURL(blob);
  //        link.download = `Budgets_Information.${'xlsx'}`; // Specify the filename for the downloaded file

  //        // Appending the link to the document body
  //        document.body.appendChild(link);

  //        // Triggering the download
  //        link.click();

  //        // Cleanup: remove the link element
  //        document.body.removeChild(link);
  //      } catch (error) {
  //        console.error("Error downloading file:", error);
  //      }
  //    };

  // download Functionlity for Excel based on ExcelType Parameter

  const handleDownloadClick = async (visibility) => {
    try {
      setLoading(true);

      if (visibility === "download") {
        await downloadExcel(ExcelType);
      } else if (visibility == "both") {
        await downloadExcel(ExcelType);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error during download:", error);
    }
  };

  const downloadExcel = async (ExcelType) => {
    debugger;
    try {
      const baseURL1 = http.defaults.baseURL;
      console.log("PageType", ExcelType);

      // Make an API request to get the template information
      const response = await fetch(`${baseURL1}/admin/downloadtemplates`);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch template information: ${response.statusText}`
        );
      }

      // Extract the template information from the API response
      const templateInfo = await response.json();
      console.log("template_infotemplate_info:", templateInfo);

      // Determine the template type and document URL based on your data
      const { templateType, documentUrl } = determineTemplateInfo(
        templateInfo,
        ExcelType
      );
      console.log("template_type_Page:", templateType);
      console.log("url_type_Page:", documentUrl);

      if (!documentUrl) {
        toast.error(`Template for ${ExcelType} not found.`);
        return;
      }

      // Make another API request to download the actual file
      const fileResponse = await fetch(documentUrl);
      const blob = await fileResponse.blob();

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${ExcelType}_template.xlsx`; // Use ExcelType for the file name
      // Appending the link to the document body
      document.body.appendChild(link);
      // Triggering the auto download
      link.click();
      // Cleanup: remove the link element
      document.body.removeChild(link);

      toast.success("File Download Successfully");
    } catch (error) {
      console.error("Error while downloading template:", error);
      toast.error("Error while downloading template");
    }
  };

  // Function to determine the template type and document URL based on your data structure and ExcelType
  const determineTemplateInfo = (templateInfo, ExcelType) => {
    debugger;
    console.log("Excel_Type", ExcelType);

    switch (ExcelType) {
      case "sales":
        return {
          templateType: "SALESPIPELINE_TEMPLATE",
          documentUrl:
            templateInfo.SALESPIPELINE_TEMPLATE?.templateDocument?.documentUrl,
        };
      case "order":
        return {
          templateType: "ORDER_TEMPLATE",
          documentUrl:
            templateInfo.ORDER_TEMPLATE?.templateDocument?.documentUrl,
        };
      case "sample":
        return {
          templateType: "SAMPLE_TEMPLATE",
          documentUrl:
            templateInfo.SAMPLE_TEMPLATE?.templateDocument?.documentUrl,
        };
      default:
        const defaultTemplate = templateInfo[ExcelType];
        if (!defaultTemplate) {
          return {
            templateType: null,
            documentUrl: null,
          };
        }
        return {
          templateType: ExcelType,
          documentUrl: defaultTemplate?.templateDocument?.documentUrl,
        };
    }
  };

  //upload funtionality for Excel based on ExcelType Parameter
  function uploadFile(ExcelType) {
    if (!files || files.length === 0) {
      // Show a message to the user about no files selected
      toast.error("No file selected to upload");
      return;
    }

    const formData = new FormData();
    formData.append("principalId", principalselect);
    formData.append("comment", description);
    formData.append("name", name);

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    // Conditionally uploading the files
    console.log("uploadFile", formData);

    if (ExcelType === "budget") {
      UserServices.budgetExcelUpload(formData)
        .then((response) => {
          console.log(response);
          setShow(false);
          // Show success toast message
          toast.success("Files Uploaded Successfully");
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          // Show error toast message
          toast.error("Failed to upload files. Please try again.");
        });
    } else if (ExcelType === "forecast") {
      UserServices.forecastExcelUpload(formData)
        .then((response) => {
          console.log(response);
          setShow(false);
          // Show success toast message
          toast.success("Files Uploaded Successfully");
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          // Show error toast message
          toast.error("Failed to upload files. Please try again.");
        });
    } else if (ExcelType === "sales") {
      UserServices.salesPipelineExcelUpload(formData)
        .then((response) => {
          console.log(response);
          setShow(false);
          // Show success toast message
          toast.success("Files Uploaded Successfully");
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          // Show error toast message
          toast.error("Failed to upload files. Please try again.");
        });
    } else {
      // Show error toast message for an invalid ExcelType
      toast.error("Invalid page type specified");
      return;
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        {visibility === "download" ||
        ExcelType === "order" ||
        ExcelType === "sample" ? (
          <button
            type="button"
            className="btn btn-success d-flex align-items-center"
            style={{ height: "45px", marginRight: "10px" }}
            onClick={() => handleDownloadClick("download")}
          >
            {!loading && (
              <i className="fa fa-download" style={{ marginRight: "5px" }} />
            )}
            <span style={{ marginRight: "auto" }}>
              <span>
                {loading ? "Downloading Template..." : "Download Template"}
              </span>
            </span>
            {loading && (
              <CircularProgress size={16} style={{ marginLeft: "5px" }} />
            )}
          </button>
        ) : (
          // null
          <>
            {ExcelType === "budget" ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={handleShow}
              >
                <i className="fa fa-upload" style={{ marginRight: "5px" }}></i>{" "}
                Upload File
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  className="btn btn-success"
                  style={{ marginRight: "10px" }}
                  onClick={() => handleDownloadClick("both")}
                >
                  {!loading && (
                    <i
                      className="fa fa-download"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  <span style={{ marginRight: "auto" }}>
                    <span>
                      {loading
                        ? "Downloading Template..."
                        : "Download Template"}
                    </span>
                  </span>
                  {loading && (
                    <CircularProgress size={16} style={{ marginLeft: "5px" }} />
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleShow}
                >
                  <i
                    className="fa fa-upload"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  Upload File
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* <ToastContainer /> */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File for Principal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option disabled>Select Principal</option>
                  {assignedPrincipalList?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>File name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder=""
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Comment</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //    console.log({ event, editor, data });
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      //multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={() => uploadFile(ExcelType)}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadExcel;
